import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Fields } from '../Fields';
import { FIELD_TYPES, getHost, getNameFromEmail } from '../../utils/utils';
import AdminJHApi from "../../utils/AdminJHApi"
import { AddToCalendarButton } from 'add-to-calendar-button-react';

export default function LeadsDialog(props) {
    const objectToEdit = props.objectToEdit
    const disabled = props.disabled || false
    const CSManagers = props.CSManagers || []
    const searchParams = props.searchParams || {}

    const questionnaire = props.questionnaire || false
    
    const [error, setError] = useState();
    const [editId, setEditId] = useState();
    const [newData, setNewData] = useState({});

    const [isDelete, setIsDelete] = useState(false);

    useEffect(() => {
        setError()
        setNewData({...objectToEdit})

        if (objectToEdit){
            setEditId(objectToEdit._id||-1)
        } else {
            setEditId()
        }

        setIsDelete(false)
    }, [objectToEdit]);

    const handleCancel = () => {
        props.setObjectToEdit()
    }

    const handleDelete = () => {
        setIsDelete(true)
    }

    const handleDeleteYes = () => {
        props.startEdit()

        AdminJHApi.deleteLead({id:editId, ...searchParams},(response)=>{
            setEditId()
            setError()
            setNewData({})

            props.finishEdit(response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.finishEdit()
        })
    }

    const handleDeleteNo = () => {
        setIsDelete(false)
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleSave = e => {
        e.preventDefault();

        props.startEdit()

        AdminJHApi.createEditLead({newData, ...searchParams, questionnaire},(response)=>{
            setEditId()
            setError()
            setNewData({})

            props.finishEdit(response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.finishEdit()
        })
    }

    const CSManagersOptions = CSManagers.map(CSManager=>{return {value:CSManager, label:getNameFromEmail(CSManager)}})

    const communicationChannels = [
        {value: "WhatsApp / Phone call", label:"WhatsApp / Phone call"},
        /*{value: "WhatsApp call", label:"WhatsApp call"},
        {value: "Phone call", label:"Phone call"},*/
        {value: "WhatsApp message", label:"WhatsApp message"},
        {value: "Google meet", label:"Google meet"},
        {value: "Meeting cancellation", label:"Meeting cancellation"},
        {value: "email", label:"email"},
        {value: "LinkedIn message", label:"LinkedIn message"},
    ]

    const fields = [
        { name: 'date', label:'Date', defaultValue: '', type:FIELD_TYPES.DATE2, sm:12},
        { name: 'email', label: 'Email', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, inputType:'email'}, 
        { name: 'name', label: 'Name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
        { name: 'phone', label: 'Phone number', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
        { name: 'csManager', label: 'CS Manager', defaultValue: '', options:CSManagersOptions, type:FIELD_TYPES.SELECT, sm:12}, 
        { name: 'communicationChannel', label: 'Communication Channel', defaultValue: '', options:communicationChannels, type:FIELD_TYPES.SELECT, sm:12}, 
        { name: 'summary', label: 'Summary', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:8, sm:12}, 
        { name: 'followUpReminderDate', label:'Follow Up Reminder', defaultValue: '', type:FIELD_TYPES.DATE_TIME, sm:12},
    ]

    const action = isDelete?'Delete':((editId===-1)?'Add':'Edit')

    return (
        <Dialog onClose={handleCancel} open={(editId!==undefined)} fullWidth maxWidth="sm">
            {<DialogTitle>{action} Lead</DialogTitle>}
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {isDelete?<Typography>Are you sure?</Typography>:
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>}
                    {(newData.followUpReminderDate&&newData.name)&&<AddToCalendarButton
                        name={"Lead follow up reminder "  + newData.name}
                        description={
                            "Follow up lead with " + newData.name + ' \n\n' + 
                            newData.email + '\n\n' + newData.internationalPhone +
                            '\n\n' + getHost() + '/questionnaires'
                        }
                        startDate={newData.followUpReminderDate.substring(0, 10)}
                        startTime={newData.followUpReminderDate.substring(11, 16)}
                        endTime={newData.followUpReminderDate.substring(11, 16)}
                        label="Add to calendar"
                        size="3"
                        options={["Google"]}
                        timeZone="Asia/Jerusalem"
                    ></AddToCalendarButton>}
                </DialogContent>
                <DialogActions>
                    {isDelete?
                    <>
                        <Button disabled={disabled} onClick={handleDeleteNo} color="primary" size="small" aria-label="No">
                            No
                        </Button>
                        <Button disabled={disabled} onClick={handleDeleteYes} color="primary" variant="outlined" size="small" aria-label="Yes">
                            Yes
                        </Button>
                    </>:<>
                        {(editId!==-1)&&<Button disabled={disabled} onClick={handleDelete} color="primary" size="small" aria-label="Delete">
                            Delete
                        </Button>}
                        <div style={{flex: "1 1 0%"}}></div>
                        <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            disabled={disabled}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label='Save'
                        >
                            Save
                        </Button>
                    </>}
                </DialogActions>
            </form>
        </Dialog>
    )
}