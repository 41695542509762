import React, { useState, useEffect } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { Button, Dialog, DialogActions, DialogContent, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import JSData from '../JSData';
import LeadsDialog from './LeadsDialog';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Fields } from '../Fields';
import { FIELD_TYPES, cleanHiddenChars, downloadCSV, getCSVContent, getDeffollowUpReminderDate } from '../../utils/utils';
import JSTablePagination from '../JSTablePagination';
//import EqualizerIcon from '@material-ui/icons/Equalizer';
import GetAppIcon from '@material-ui/icons/GetApp';

function Search(props) {
    //const cells = props.cells || []

    //const filteredCells = cells.filter(cell => cell.search)

    const [search, setSearch] = useState('');
    //const [fields, setFields] = useState([]);

    const handleChangeSearch = e => {
        setSearch(e.target.value)
    };

    /*const handleToggleField = (event, newFields) => {
        setFields(newFields||[]);
    };*/

    const handleSearch = e => {
        e.preventDefault();
        //props.handleSearch(search, fields)
        props.handleSearch(search, [])
    }

    return (
        <form onSubmit={handleSearch}>
            <TextField size='small' variant='outlined' placeholder="Search" margin="none" value={search || ''} onChange={handleChangeSearch} type="search"/>
            {/*(filteredCells.length>0)&&<ToggleButtonGroup value={fields} onChange={handleToggleField} size='small' style={{margin: '0 8px'}}>
                {filteredCells.map(cell => (
                    <ToggleButton key={cell.id} value={cell.id} aria-label={cell.label}>
                        {cell.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>*/}
            <IconButton disabled={props.disabled/*||(fields.length===0&&search!=='')*/} aria-label="Search" color='primary' style={{padding: 10}} onClick={handleSearch} type="submit">
                <SearchIcon fontSize='small' />
            </IconButton>
        </form>
    )
}

function SearchExtra(props) {
    const extraSearch = props.extraSearch || {}
    const showExtraSearch = props.showExtraSearch || false
    const disabled = props.disabled || false

    const [newData, setNewData] = useState({...extraSearch});

    useEffect(() => {
        if(showExtraSearch){
            setNewData({...extraSearch})
        }
    }, [showExtraSearch, extraSearch]);

    const handleCancel = () => {
        setNewData({...extraSearch})
        props.setShowExtraSearch(false)
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleSave = e => {
        e.preventDefault();

        props.search(newData)
    }

    const fields = [
        { name: 'from', label:'From', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
        { name: 'to', label:'To', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
    ]

    return (
        <Dialog onClose={handleCancel} open={showExtraSearch} fullWidth maxWidth="xs">
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                    {<Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Search'
                    >
                        Search
                    </Button>}
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default function Leads(props) {
    const userEmail = props.userEmail

    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [cells, setCells] = useState([]);
    const [error, setError] = useState();
    const [disabled, setDisabled] = useState(false);
    const [CSManagers, setCSManagers] = useState([]);

    const [search, setSearch] = useState('');
    const [showExtraSearch, setShowExtraSearch] = useState(false);
    const [extraSearch, setExtraSearch] = useState({});
    const [fieldsToSearch, setFieldsToSearch] = useState([]);
    const [page, setPage] = useState(0);

    const [objectToEdit, setObjectToEdit] = useState();

    const today = new Date().toISOString().substring(0, 10)

    useEffect(() => {
        const now = new Date();
        const from = new Date(now.getFullYear()-1, now.getMonth(), now.getDate()).toISOString()
        const to = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2).toISOString()

        AdminJHApi.getLeadsData({extraSearch:{from, to}},(response)=>{
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setCSManagers(response.CSManagers||[])
            setExtraSearch({from, to})
            setError()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
        })
    }, []);

    const handleChangePage = newPage => {
        setDisabled(true)

        AdminJHApi.getLeadsData({search, fieldsToSearch, extraSearch, page:newPage},(response)=>{
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setPage(newPage);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    };

    const handleSearch = (searchParam, fieldsToSearch) => {
        const search = cleanHiddenChars(searchParam)

        setSearch(search||'')
        setFieldsToSearch(fieldsToSearch||[])
        setDisabled(true)

        AdminJHApi.getLeadsData({search, fieldsToSearch, extraSearch},(response)=>{
            setData(response.data||[])
            setCount(response.count||0)
            setPage(0);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleNew=()=>{
        setObjectToEdit({
            date:today,
            csManager:userEmail,
            communicationChannel: 'Google meet',
            followUpReminderDate: getDeffollowUpReminderDate()
        })
    }

    const handleEditRow=(lead, params={})=>{
        setObjectToEdit({...lead})
    }

    const startEdit = () => {
        setDisabled(true)
    }

    const finishEdit = response => {
        if(response){
            setData(response.data||[])
            setCount(response.count||0)
        }

        setDisabled(false)
    }

    const propsToChildren = {
        disabled,
        startEdit,
        finishEdit,
        setObjectToEdit,
    }

    let CSManagersWirhUser = [...CSManagers]
    if (!CSManagersWirhUser.includes(userEmail)){
        CSManagersWirhUser.push(userEmail)
    }

    if (objectToEdit && objectToEdit.csManager && !CSManagersWirhUser.includes(objectToEdit.csManager)){
        CSManagersWirhUser.push(objectToEdit.csManager)
    }

    const handleShowFilter = () => {
        setShowExtraSearch(true)
    };

    const handleExtraSearch = newSearch => {
        setExtraSearch(newSearch||{})
        setShowExtraSearch(false)
        setDisabled(true)

        AdminJHApi.getLeadsData({search, fieldsToSearch, extraSearch:newSearch},(response)=>{
            setData(response.data||[])
            setCount(response.count||0)
            setPage(0);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const exportToCsv = () => {
        if (count > 100){
            AdminJHApi.getLeadsData({search, fieldsToSearch, extraSearch, noLimit:true},(response)=>{
                const csvContent = getCSVContent(response.cells||[], response.data||[])
                downloadCSV(csvContent, `Leads.csv`)
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
                setDisabled(false)
            })
        } else {
            const csvContent = getCSVContent(cells, data)
            downloadCSV(csvContent, `Leads.csv`)
        }
    }

    /*const handleShowStats = () => {

    }*/

    return (
        <div className="fullWidthContainer" >
            <LeadsDialog {...propsToChildren} objectToEdit={objectToEdit} CSManagers={CSManagersWirhUser} searchParams={{search, fieldsToSearch, extraSearch, page}}/>
            <SearchExtra 
                extraSearch={extraSearch} 
                disabled={disabled} 
                showExtraSearch={showExtraSearch} 
                setShowExtraSearch={setShowExtraSearch} 
                search={handleExtraSearch}
            />

            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} elevation={1}>
                <div>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {(cells.length>0)&&<Search handleSearch={handleSearch} disabled={disabled} cells={cells}/>}
                </div>
                <IconButton disabled={props.disabled} aria-label="Filter" color='primary' style={{padding: 10}} onClick={handleShowFilter}>
                    <FilterListIcon fontSize='small' />
                </IconButton>
            </Paper>
            <Paper style={{margin:'2px 4px', position:'relative', overflow:'auto', height:'calc(100% - 112px)'}} elevation={1}>
                <JSData cells={cells} data={data} handleNewRow={handleNew} handleEditRow={handleEditRow}/>
            </Paper>
            <Paper style={{margin:'0 4px'}} elevation={1}>
                <JSTablePagination
                    count={count}
                    rowsPerPage={100}
                    page={page}
                    onChangePage={handleChangePage}
                    disabled={disabled}
                >
                    <IconButton onClick={exportToCsv} disabled={disabled} color='primary'>
                        <GetAppIcon/>
                    </IconButton>
                    {/*<IconButton onClick={handleShowStats} disabled={disabled} color='primary'>
                        <EqualizerIcon/>
                    </IconButton>*/}
                </JSTablePagination>
            </Paper>
        </div>
    )
}